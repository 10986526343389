$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        hideCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {
        $('.page-navi').stick_in_parent({
            parent: 'body'
        });
    };

    if ( breakpoint['large'] ) {
        var $button = null,
            _hidden = true,
            _toggle = 'visible',
            _arrows = 'slick-arrow',
            _button = 'gallery-close',
            _images = '.gallery';

        $('.slide').slick({
            autoplaySpeed: 6000,
            lazyLoad: 'progressive',
            speed: 2000,
            swipe: touchEnabled,
            slide: 'div',
            prevArrow: '<button class="toggle ' + _toggle + ' slick-arrow slick-prev"><svg enable-background="new 0 0 58 200" version="1.1" viewBox="0 0 58 200" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="M58,200L1.652,100.004L58,0h-1.649L0,100.004L56.351,200H58z" clip-rule="evenodd" fill="#fff" fill-rule="evenodd"/></svg></button>',
            nextArrow: '<button class="toggle ' + _toggle + ' slick-arrow slick-next"><svg enable-background="new 0 0 58 200" version="1.1" viewBox="0 0 58 200" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" fill="#fff" d="M1.65,200L58,100.004L1.65,0H0l56.349,100.004L0,200H1.65z"/></svg></button>'
        });

        $('.gallery').each(function(){

            var _href = '#' + $(this).attr('id');

            $(this).find('.' + _arrows).removeClass(_toggle);

            $button = $('<button/>', {
                'class': _button,
                'data-href': '#' + $(this).attr('id'),
                'html': '<svg enable-background="new 0 0 36.77 36.77" version="1.1" viewBox="0 0 36.77 36.77" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><polygon points="36.77 1.414 35.355 0 18.385 16.971 1.414 0 0 1.414 16.971 18.385 0 35.355 1.415 36.77 18.385 19.799 35.355 36.77 36.77 35.355 19.799 18.385" clip-rule="evenodd" fill="#fff" fill-rule="evenodd"/></svg>'
            })
            .appendTo( $(this) )
        });

        $button.bind('click', function() {

            _hidden = true;
            $('.toggle:not(.gallery), ' + _href).toggleClass(_toggle);
        });

        $('.image-dots a').bind('click', function(evt){
            evt.preventDefault();

            var _hidden = false,
                $this = $(this),
                _href = $this.attr('href');

            $( _href ).slick('slickGoTo', $this.parent().index(), true);

            if( !_hidden ) $('.toggle:not(.gallery), ' + _href).toggleClass(_toggle);
        })

        $('.project-dots a').bind('click', function(evt){
            evt.preventDefault();

            $('.projects').slick('slickGoTo', $(this).index());
        })
    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


    /**
     * PREVENTS CONTENT SCROLLING AFTER TOGGELING
     */
    // naviToggled.onchange('change');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/